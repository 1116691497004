


























































































































































import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import SaveButton from './SaveButton.vue';
import TVButton from './TVButton.vue';
import { i18n } from '../../lang/i18n.config';

export default Vue.extend({
  name: 'PageHeading',
  components: { SaveButton, TVButton },
  props: {
    slug: {
      type: String,
      required: true,
      default: 'questions',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    currentSession: {
      type: Object,
      required: true,
    },
    showDescriptionField: {
      type: Boolean,
      required: false,
      default: true,
    },
    showButtonField: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonPlaceholder: {
      type: String,
      required: false,
      default: i18n.t('components.presenter.pageHeadingNewQuestions'),
    },
    hideTVButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      labels: 'presenter/getLabels',
      descriptions: 'presenter/getDescriptions',
      buttonLabels: 'presenter/getButtonLabels',
    }),
    computedTitleValue(): string {
      return (
        this.labels[this.slug] ||
        (this.currentSession.labels && this.currentSession.labels[this.slug]) ||
        this.placeholder
      );
    },
    computedButtonValue(): string {
      return (
        this.buttonLabels[this.slug] ||
        (this.currentSession.buttonLabels &&
          this.currentSession.buttonLabels[this.slug]) ||
        this.buttonPlaceholder
      );
    },
  },
  methods: {
    ...mapMutations({
      setLabel: 'presenter/SET_SESSION_LABEL',
      setDescription: 'presenter/SET_SESSION_DESCRIPTION',
      setButtonLabel: 'presenter/SET_SESSION_BUTTON_LABEL',
    }),
    saveLabel() {
      this.$store.dispatch('presenter/updateSessionLabel', {
        label: this.labels[this.slug],
        labelId: this.slug,
      });
    },
    saveDescription() {
      this.$store.dispatch('presenter/updateSessionDescription', {
        description: this.descriptions[this.slug],
        descriptionId: this.slug,
      });
    },
    saveButtonLabel() {
      this.$store.dispatch('presenter/updateSessionButtonLabel', {
        buttonLabel: this.buttonLabels[this.slug],
        buttonId: this.slug,
      });
    },
  },
});
